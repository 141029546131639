export default {
  version: "2.4.0",
  baseUrl: "https://t4tapi.kolaborasikproject.com/api/",
  // baseUrl: "http://localhost:8000/api/",
  baseUrlUpload: "https://t4tadmin.kolaborasikproject.com",
  // baseUrlExport: "http://localhost:9056/",
  baseUrlExport: "https://geko-export.tftdemo.net/",
  // baseUrlNursery: 'https://backend.t4t-api.org/api',
  baseUrlNursery: "https://api-nursery.t4t-api.org/api",
  // mapBoxApi:
  //   "pk.eyJ1IjoiYmlsbGlvbmFpcmUwMjkiLCJhIjoiY2p3a3V5Zms5MHdzaTN5bnhxenFvcHFiNiJ9.jrcibCk1tIIShgL_mh7ipA",
  mapBoxApi:
    "pk.eyJ1IjoiZGV2dDR0IiwiYSI6ImNtMm1zc3M0NDBxeXcyanFzd25ndnlva3cifQ.pBINatrH39ajJ_ILIHoGsg",
  mapBoxStyle: "mapbox://styles/mapbox/satellite-streets-v12",
  mapBoxStyleLight: "mapbox://styles/mapbox/light-v11",
  programYear: {
    model: "2024",
    options: ["2020", "2021", "2022", "2023", "2024"],
  },
};
